import { Routes } from '@angular/router';
import { StartPageComponent } from './pages/start-page/start-page.component';
import { AwardsPageComponent } from './pages/awards-page/awards-page.component';
import { GamePageComponent } from './pages/game-page/game-page.component';

export const routes: Routes = [
	{
		path: '',
		component: StartPageComponent,
		pathMatch: 'full',
	},
	{
		path: 'awards',
		component: AwardsPageComponent,
	},
	{
		path: 'game',
		component: GamePageComponent,
	},
];
