<ng-container *ngIf="currentTab === 'trophy'">

<p class="awards-page__mural">ANNI-MURAL</p>
  <div class="awards-page__players">
    <img [src]="'./assets/images/lvl_'+ (currentLevel - 1) + '.webp'" alt="players">
<!--    <img [src]="'./assets/images/Lvl '+ (currentLevel - 1) + '.png'" alt="players">-->
  </div>
</ng-container>
  <div class="awards-page__progress">
    <div class="awards-page__line">
      <div class="awards-page__line-inner" [ngStyle]="{'width': (currentLevel - 1)*20 +'%' }"></div>
    </div>
    <div class="awards-page__counter">
      {{currentLevel - 1}}/5
    </div>
  </div>
  <div class="awards-page__bottom">
    <img class="awards-page__gift" role="button" src="./assets/images/button_gift_question.png" alt="gift" (click)="showOnboarding.emit('show')">
    <div class="awards-page__text">
      Gather all the Kerala Blasters stars and win <button class="awards-page__prizes-btn" (click)="showPrizes.emit('show')" >valuable prizes!</button></div>
  </div>
