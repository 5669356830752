<div class="game-page" #gamePage>
  <app-header [level]="currentLevel$ | async" [areaToFill]="areaToFill$ | async" [restLives]="restLives$ | async" [filledSquare]="filledArea$ | async" ></app-header>
  <div class="game-field" #field ></div>
  <div class="fireworks" #fireworks ></div>

</div>

<app-controls-modal *ngIf="(controls$ | async)=== 'show' && (currentLevel$ | async) ===1" [device]="deviceType$ | async" (closeModal)="controls$.next('close')"></app-controls-modal>
<div class="failure" #failureWrapper>
  <img class="failure__image" src="./assets/images/whistle-red.svg" alt="whistle" #failureWhistle>
</div>
