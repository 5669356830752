import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { LevelState, ModalType } from '../../models/helper-types.model';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'app-page-awards-buttons',
	standalone: true,
	imports: [NgSwitch, NgSwitchCase, NgIf, RouterLink, NgClass],
	templateUrl: './awards-page-buttons.component.html',
	styleUrl: './awards-page-buttons.component.scss',
})
export class AwardsPageButtonsComponent {
	@Input() currentLevel: number;
	@Input() levelState: LevelState;
	@Output() changeLevelState = new EventEmitter<LevelState>();
	@Output() changeLevel = new EventEmitter<number>();
	@Output() showAuthModal = new EventEmitter<ModalType>();
	@Output() showOnboardingModal = new EventEmitter<ModalType>();

	changeLevelSettings(levelState: LevelState, level?: number) {
    console.log(levelState);
		if (level) {
			this.changeLevel.emit(level);
		}
		this.changeLevelState.emit(levelState);
	}
}
