<ng-container *ngIf="levelState === 'failed'">
	<div class="awards-page__tabs" *ngIf="currentTab">
		<button class="awards-page__tab _active"><span>DEFEAT</span></button>
	</div>
</ng-container>
<ng-container *ngIf="levelState !== 'failed'">
	<div class="awards-page__tabs" *ngIf="currentTab">
		<button class="awards-page__tab _active" [ngClass]="{ mural: currentTab === 'trophy'}"
			><span>{{ currentTab === 'trophy' ? 'MURAL' : 'STORY' }}</span></button
		>
		<button (click)="changeTab.emit()" class="awards-page__tab"
			><span>{{ currentTab === 'info' ? 'MURAL' : 'STORY' }}</span></button
		>
	</div>
</ng-container>
