<div class="awards-page" *ngIf="currentLevel$ | async as level">
	<app-awards-page-popup
		[currentTab]="currentTab$ | async"
		[level]="currentLevel$ | async"
		[levelState]="levelState$ | async"></app-awards-page-popup>

	<app-awards-page-tabs
		[levelState]="levelState$ | async"
		[currentTab]="currentTab$ | async"
		(changeTab)="changeTab()"></app-awards-page-tabs>

	<app-awards-page-section
		class="awards-page__section"
		[currentTab]="currentTab$ | async"
		[currentLevel]="currentLevel$ | async"
		(showOnboarding)="onboarding$.next($event)"
		(showPrizes)="prizes$.next($event)"
  ></app-awards-page-section>
	<app-page-awards-buttons
		[currentLevel]="currentLevel$ | async"
		[levelState]="levelState$ | async"
		(changeLevelState)="setNewLevelState($event)"
		(showOnboardingModal)="showOnboarding(true)"
		(showAuthModal)="toggleModal()"
  ></app-page-awards-buttons>
	<a class="awards-page__fontbet" href="#"
		><img src="./assets/images/batery-logo.png" alt="battery-logo"
	/></a>
</div>
<app-authentication-modal
	*ngIf="(showAuthModal$ | async) === 'show'"
	[currentLevel]="currentLevel$ | async"
	(closeModal)="showAuthModal$.next('close')"
	(showOnboarding)="onboarding$.next('show')"
	(showCongratulationsModal)="
		congratulation$.next('show')
	"></app-authentication-modal>
<app-onboarding-modal
	*ngIf="(onboarding$ | async) === 'show'"
  [withAuth]="onboardingWithAuth$ | async"
	(closeModal)="closeOnboarding()"
  (showModal)="toggleModal()"
></app-onboarding-modal>
<app-awards-description-modal
	*ngIf="(prizes$ | async) === 'show'"
	(closeModal)="closePrizes()"
></app-awards-description-modal>
<app-congratulation-modal
	*ngIf="(congratulation$ | async) === 'show'"
  (closeModal)="closeCongratulation()"
></app-congratulation-modal>
