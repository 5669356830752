import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
} from '@angular/core';
import {
	ControlsModalComponent,
	DeviceType,
} from '../modals/controls-modal/controls-modal.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { AuthenticationModalComponent } from '../modals/authentication-modal/authentication-modal.component';
import { CongratulationModalComponent } from '../modals/congratulation-modal/congratulation-modal.component';
import { FailureModalComponent } from '../modals/failure-modal/failure-modal.component';
import { HeaderComponent } from '../header/header.component';
import { GameService } from '../../services/game.service';
import { HammerModule } from '@angular/platform-browser';
import { ModalType } from '../../models/helper-types.model';

@Component({
	selector: 'app-smart-game',
	standalone: true,
	imports: [
		ControlsModalComponent,
		NgIf,
		AsyncPipe,
		AuthenticationModalComponent,
		CongratulationModalComponent,
		FailureModalComponent,
		HeaderComponent,
		HammerModule,
	],
	templateUrl: './smart-game.component.html',
	styleUrl: './smart-game.component.scss',
})
export class SmartGameComponent implements AfterViewInit, OnInit, OnDestroy {
	public deviceType$ = new BehaviorSubject<DeviceType>('desktop');
	public controls$ = new BehaviorSubject<ModalType>('show');
	public restLives$: Observable<number>;
	public filledArea$: Observable<number>;
	public areaToFill$: Observable<number>;
	public currentLevel$: Observable<number>;
	public aspectRatio = 320 / 500;
	constructor(
		private gameService: GameService,
		private renderer: Renderer2
	) {}

	@ViewChild('field') gameContainer: ElementRef;
	@ViewChild('gamePage') gamePage: ElementRef;
	@ViewChild('fireworks') fireworks: ElementRef;
	@ViewChild('failureWrapper') failureWrapper: ElementRef;
	@ViewChild('failureWhistle') failureWhistle: ElementRef;

	@HostListener('window:resize', ['$event'])
	onResize() {
		if (this.gameService?.app) {
			this.gameService.app.resize();
			this.calculateContainer();
		}
		this.checkDevice();
	}

	@HostListener('window:keydown', ['$event'])
	handleKeyDown(event: KeyboardEvent) {
		switch (event.key) {
			case 'ArrowUp':
			case 'W':
			case 'w':
			case 'Ц':
			case 'ц':
				this.closeControlModal();
				this.moveUp();
				break;
			case 'S':
			case 's':
			case 'Ы':
			case 'ы':
			case 'ArrowDown':
				this.closeControlModal();
				this.moveDown();
				break;
			case 'A':
			case 'a':
			case 'Ф':
			case 'ф':
			case 'ArrowLeft':
				this.closeControlModal();
				this.moveLeft();
				break;
			case 'D':
			case 'd':
			case 'В':
			case 'в':
			case 'ArrowRight':
				this.closeControlModal();
				this.moveRight();
				break;
		}
	}

	closeControlModal(): void {
		if (this.controls$.value === 'show') {
			this.controls$.next('close');
		}
	}

	moveUp(): void {
		this.gameService.player.moveUp();
	}
	moveDown(): void {
		this.gameService.player.moveDown();
	}
	moveLeft(): void {
		this.gameService.player.moveLeft();
	}
	moveRight(): void {
		this.gameService.player.moveRight();
	}

	ngOnInit() {
		this.gameService.renderer = this.renderer;
		this.checkDevice();
		this.currentLevel$ = this.gameService.currentLevel$.asObservable();
		this.restLives$ = this.gameService.restLives$;
		this.filledArea$ = this.gameService.filledArea$;
		this.areaToFill$ = this.gameService.areaToFill$.asObservable();
	}

	ngAfterViewInit() {
		this.calculateContainer();
		this.setContainers();
		this.gameService.aspectRatio = this.aspectRatio;
		this.gameService.startGame();
		const element = this.gamePage.nativeElement;
		const hammer = new Hammer(element);
		hammer
			.get('swipe')
			.set({ direction: Hammer.DIRECTION_ALL, threshold: 0.5, velocity: 0.1 });
		hammer.on('swiperight swipeleft swipeup swipedown', event => {
			event.preventDefault();
			switch (event.type) {
				case 'swiperight':
					this.moveRight();
					break;
				case 'swipeleft':
					this.moveLeft();
					break;
				case 'swipeup':
					this.moveUp();
					break;
				case 'swipedown':
					this.moveDown();
					break;
			}
		});
	}

	ngOnDestroy() {
		this.gameService.stopGame();
	}

	private setContainers() {
		this.gameService.gameContainer = this.gameContainer;
		this.gameService.fireworksContainer = this.fireworks;
		this.gameService.failureWrapperContainer = this.failureWrapper;
		this.gameService.failureWhistleContainer = this.failureWhistle;
	}

	calculateContainer(): void {
		const containerWidth = this.gameContainer.nativeElement.offsetWidth;
		const containerHeight = containerWidth / this.aspectRatio;
		this.renderer.setStyle(
			this.gameContainer.nativeElement,
			'height',
			`${containerHeight}px`
		);
	}

	private checkDevice() {
		const screenWidth = window.innerWidth;
		if (screenWidth < 768) {
			// Пример изменения высоты корневого элемента приложения
			this.deviceType$.next('mobile');
		} else {
			// Сброс стиля для десктопных устройств
			this.deviceType$.next('desktop');
		}
	}
}
