<div
	class="awards-page__popup"
	[ngClass]="{ '_no-image': currentTab === 'trophy', borderless: levelState === 'failed'}">
	<ng-container *ngIf="levelState !== 'failed'">
		<div class="awards-page__popup-top" *ngIf="currentTab">
			<ng-container *ngIf="currentTab === 'info'" [ngSwitch]="levelState">
				<img
					class="awards-page__popup-image"
					*ngSwitchCase="'start'"
					[src]="'./assets/images/levels/level_'+level+'_start.png'"
					alt="photo" />
				<img
					class="awards-page__popup-image"
					*ngSwitchCase="'finish'"
					[src]="'./assets/images/levels/level_'+(level - 1)+'_finish.png'"
					alt="photo" />
				<img
					class="awards-page__popup-image"
					*ngSwitchCase="'failed'"
					src="./assets/images/failed.png"
					alt="photo" />
				<img
					class="awards-page__popup-image"
					*ngSwitchCase="'default'"
          [src]="'./assets/images/levels/level_'+level+'_start.png'"

					alt="photo" />
			</ng-container>
<!--			<div-->
<!--				class="awards-page__popup-info"-->
<!--				[ngClass]="{ trophy: currentTab === 'trophy' }"-->
<!--        [innerHtml]="currentTab === 'trophy' ? 'Коллекция трофеев' : infoText"></div>-->
      <div class="awards-page__popup-info" [innerHtml]="infoText"></div>
		</div>
	</ng-container>
	<div class="awards-page__popup-top failed" *ngIf="levelState === 'failed'">
		<img
			class="awards-page__popup-image failed"
			src="./assets/images/icon_card_box.svg"
			alt="photo" />
	</div>
	<ng-container *ngIf="currentTab === 'info'">
		<div *ngIf="levelState" class="awards-page__popup-text" [innerHtml]="text"></div>
	</ng-container>
</div>
