import { Component } from '@angular/core';
import { SmartGameComponent } from '../../components/smart-game/smart-game.component';
import { ControlsModalComponent } from '../../components/modals/controls-modal/controls-modal.component';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
	selector: 'app-game-page',
	standalone: true,
	imports: [SmartGameComponent, ControlsModalComponent, NgIf, AsyncPipe],
	templateUrl: './game-page.component.html',
	styleUrl: './game-page.component.scss',
})
export class GamePageComponent {}
