import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForOf, NgIf, NgStyle } from '@angular/common';
import {
	AwardTabType,
	LevelState,
	ModalType,
} from '../../models/helper-types.model';

@Component({
	selector: 'app-awards-page-section',
	standalone: true,
	imports: [NgStyle, NgIf, NgForOf],
	templateUrl: './awards-page-section.component.html',
	styleUrl: './awards-page-section.component.scss',
})
export class AwardsPageSectionComponent {
	@Input() currentTab: AwardTabType;
	@Input() currentLevel: number;
	@Output() showOnboarding = new EventEmitter<ModalType>();
	@Output() showPrizes = new EventEmitter<ModalType>();
	trophies: string[] = [
		'trophy-1',
		'trophy-2',
		'trophy-3',
		'trophy-4',
		'trophy-5',
	];
}
