import { Component, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-failure-modal',
	standalone: true,
	imports: [],
	templateUrl: './failure-modal.component.html',
	styleUrl: './failure-modal.component.scss',
})
export class FailureModalComponent {
	@Output() closeModal = new EventEmitter<void>();
}
