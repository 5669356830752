import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [CommonModule, RouterOutlet],
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'Король поля';
	@ViewChild('wrapper') wrapper: ElementRef;

	constructor(
		private renderer: Renderer2,
		private el: ElementRef
	) {
		this.adjustScreenSize();
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.adjustScreenSize();
	}

	private adjustScreenSize(): void {
		const screenHeight = window.innerHeight;
		const screenWidth = window.innerWidth;
		this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
		// Проверка на мобильное устройство по ширине экрана (можно адаптировать условие под свои нужды)
		if (screenWidth < 768) {
			// Пример изменения высоты корневого элемента приложения
			this.renderer.setStyle(
				this.el.nativeElement,
				'height',
				`${screenHeight}px`
			);
		} else {
			// Сброс стиля для десктопных устройств
			this.renderer.setStyle(this.el.nativeElement, 'height', `100svh`);
		}
	}
}
