<div class="awards-description-modal">
  <div class="awards-description-modal__slider">
    <swiper-container class="awards-description-modal__swiper" init="false" appSwiper>
      @for (slide of slides; track slide.id) {
      <swiper-slide class="awards-description-modal__slide">
        <img class="awards-description-modal__image" src='./assets/images/gift.svg' alt="image">
<!--        <img class="awards-description-modal__image" [src]='slide.image' alt="image">-->
        <div class="awards-description-modal__title">{{slide.title}}</div>
        <div class="awards-description-modal__subtitle">{{slide.subTitle}}</div>
        <div class="awards-description-modal__text">{{slide.description}}</div>
      </swiper-slide>
      }
    </swiper-container>
  </div>
  <button class="awards-description-modal__arrow" id="slide-prev">
    <img src="../assets/images/close.png" alt="arrow">
  </button>
  <button class="awards-description-modal__arrow _right" id="slide-next">
    <img src="../assets/images/close.png" alt="arrow">
  </button>
  <button class="button _blue" (click)="closeModal.emit()">Wooah!</button>
</div>
