import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {User, UserVerified} from "../models/api-types.interface";
import {environment} from "../../environments/environment";
import {map, Observable, tap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private readonly api = environment.api;
  public userHashCode: string;
  constructor(private http: HttpClient) { }

  sendUser(data: User): Observable<string>{
    return this.http.post<{data: {hashCode: string}}>(this.api + '/game/user', data).pipe(map(res=> res.data.hashCode), tap(code=> this.userHashCode = code))
  }

  verifyUser(code: string): Observable<{
    "data": "string",
    "message": "string"
  }>{
    const data: UserVerified = {
      code,
      hashCode: this.userHashCode
    }
    return this.http.post<{
      "data": "string",
      "message": "string"
    }>(this.api + '/game/verification', data)
  }
}
