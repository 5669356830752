import { Component, Input } from '@angular/core';
import { NgForOf, NgStyle } from '@angular/common';

@Component({
	selector: 'app-header',
	standalone: true,
	imports: [NgForOf, NgStyle],
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss',
})
export class HeaderComponent {
	@Input() restLives: number;
	@Input() level: number = 1;
	@Input() filledSquare: number = 0;
	@Input() areaToFill: number = 0;
	lives: any[] = ['', ''];
	years: string[] = ['15/16', '17/18', '18/19', '21/22', '22/23'];

	get filledArea(): number {
		if (this.filledSquare && this.areaToFill) {
			const area = Math.floor((this.filledSquare * 100) / this.areaToFill);
			return area >= 100 ? 100 : area;
		} else {
			return 0;
		}
	}
}
