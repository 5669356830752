import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import * as PIXI from 'pixi.js';
import {
	Assets,
	AssetsClass,
	Rectangle,
	SCALE_MODES,
	Sprite,
	Texture,
} from 'pixi.js';
import { Player } from '../models/player.model';
import { GAME_MATRIX, GAME_MATRIX_FINISH } from '../../assets/data';
import { BehaviorSubject, Observable } from 'rxjs';
import { Enemy } from '../models/enemy.model';
import { LevelState, PlayerDirection } from '../models/helper-types.model';
import { Router } from '@angular/router';
import { Fireworks } from 'fireworks-js';
import {PlayerName, playerNameMapper} from "../models/player-name.mapper";
import {environment} from "../../environments/environment";

@Injectable({
	providedIn: 'root',
})
export class GameService {
	public player: Player;
	public enemies: Enemy[];
	public restLives$ = new BehaviorSubject<number>(2);
	public filledArea$ = new BehaviorSubject<number>(0);
	public currentLevel$ = new BehaviorSubject<number>(1);
	private _app: PIXI.Application;
	private _gameContainer: ElementRef;
	public fireworksContainer: ElementRef;
	public failureWrapperContainer: ElementRef;
	public failureWhistleContainer: ElementRef;
	private _assets: AssetsClass;
	private assetsAliases: string[] = [];
	private finishBackgroundAlias: string;
	private assetsEnemiesAliases: string[] = [];
	private _aspectRatio: number;
	private gridWidth = 19;
	private gridHeight = 29;
	private cellSize: number;
	private maskGraphics: PIXI.Graphics;
	private finishImage: PIXI.Sprite;
	private gameMatrix: number[][] = GAME_MATRIX.map(row => [...row]);
	private _renderer: Renderer2;
	private area: number;
	private _areaToFill$ = new BehaviorSubject<number>(60);
	public _levelState$ = new BehaviorSubject<LevelState>('start');
  private playerName: PlayerName;
	//setters and getter
	set areaToFill$(area: number) {
		this._areaToFill$.next(area);
	}

	get areaToFill$(): BehaviorSubject<number> {
		return this._areaToFill$;
	}

	set levelState$(state: LevelState) {
		this._levelState$.next(state);
	}

	get levelState$(): Observable<LevelState> {
		return this._levelState$.asObservable();
	}

	set app(app: PIXI.Application) {
		this._app = app;
	}

	get app(): PIXI.Application {
		return this._app;
	}

	set aspectRatio(ratio: number) {
		this._aspectRatio = ratio;
	}

	get aspectRatio(): number {
		return this._aspectRatio;
	}

	set assets(assets: AssetsClass) {
		this._assets = assets;
	}

	get assets(): AssetsClass {
		return this._assets;
	}

	set gameContainer(container: ElementRef) {
		this._gameContainer = container;
	}

	get gameContainer(): ElementRef {
		return this._gameContainer;
	}

	set renderer(renderer: Renderer2) {
		this._renderer = renderer;
	}

	get renderer(): Renderer2 {
		return this._renderer;
	}

	constructor(private router: Router) {
		this.calculateArea();
	}

	public prepareGame(): void {
		switch (this.currentLevel$.value) {
			case 1:
				this.areaToFill$ = 60;
				break;
			case 2:
				this.areaToFill$ = 65;
				break;
			case 3:
				this.areaToFill$ = 70;
				break;
			case 4:
				this.areaToFill$ = 75;
				break;
			case 5:
				this.areaToFill$ = 80;
				break;
		}
    this.initPixiApp();
		this.loadAssets().then(() => this.setup());
	}

	public startGame(): void {
		if (this.app && this.app.ticker) {
			this.resetGame();
		}
		this.prepareGame();
	}

	public stopGame(): void {
		this.resetGame();
	}

	public loseGame(): void {
		this.levelState$ = 'failed';
		this.resetGame();
		this.currentLevel$.next(this.currentLevel$.value);
		this.router.navigate(['awards']);
	}

	public finishGame(): void {
		this.app.ticker.stop();
		this.app.stage.removeChild(this.player.displayObject);
		this.enemies.forEach(e => this.app.stage.removeChild(e.displayObject));

		this.app.stop();
		this.startFireWorks();
		this.levelState$ = 'finish';
		this.updateMaskWithMatrix(GAME_MATRIX_FINISH);
		this.finishImage.filters = null;
	}

	private resetGame() {
		if (this.app.ticker) {
			this.app.ticker.stop();
			this.app.stop();
			this.app.destroy();
		}
		this.assetsEnemiesAliases = [];
		this.assetsAliases = [];
		this.assets = null;
		this.player = null;
		this.enemies = [];
		this.resetMatrix();
		this.restLives$.next(2);
		this.filledArea$.next(0);
		this.maskGraphics = null;
	}

	//prepare methods

	private calculateArea() {
		let area = 0;
		for (let y = 0; y < this.gameMatrix.length; y++) {
			for (let x = 0; x < this.gameMatrix[y].length; x++) {
				if (this.gameMatrix[y][x] === 0) {
					area++;
				}
			}
		}
		this.area = area;
	}

	private calculateCellSize() {
		const containerWidth = this.gameContainer.nativeElement.clientWidth;
		const containerHeight = this.gameContainer.nativeElement.clientHeight;

		// Рассчитываем максимально возможный размер клетки
		const cellSizeByWidth = containerWidth / this.gridWidth;
		const cellSizeByHeight = containerHeight / this.gridHeight;

		// Выбираем наименьший размер, чтобы сохранить соотношение сторон
		let cellSize = Math.min(cellSizeByWidth, cellSizeByHeight);

		// Проверка соотношения сторон
		if (
			(cellSize * this.gridHeight) / (cellSize * this.gridWidth) >
			this.aspectRatio
		) {
			cellSize = containerWidth / this.aspectRatio / this.gridHeight;
		}

		this.cellSize = cellSize;
	}

	private initPixiApp(): void {
		this.calculateCellSize();
		const appWidth = this.cellSize * this.gridWidth;
		const appHeight = this.cellSize * this.gridHeight;
    this.playerName = playerNameMapper[this.currentLevel$.value]
		this.app = new PIXI.Application({
			width: appWidth,
			height: appHeight,
			autoDensity: true,
			resolution: window.devicePixelRatio || 1,
			resizeTo: this.gameContainer.nativeElement,
			antialias: true,
			forceCanvas: false,
		});
		this.gameContainer.nativeElement.appendChild(this.app.view);
		this.prepareAssetClass();
	}

	private prepareAssetClass(): void {
		this.assets = Assets;
		this.assets.init({ basePath: 'assets/game' });
	}

	private loadAssets(): Promise<Record<string, any>> {
		this.addAssets();
		return this.assets.load([
			...this.assetsAliases,
			...this.assetsEnemiesAliases,
		]);
	}

	private addAssets(): void {
		this.loadFieldBackground();
		this.loadFinishBackground();
		this.loadArrows();
		this.loadPlayerAnimations();
		this.loadEnemyAnimations();
	}

	private loadFieldBackground(): void {
		const alias: string = 'field';
		this.assets.add({
			alias,
			src: 'game-field.svg',
			data: { scaleMode: SCALE_MODES.LINEAR, loadParser: 'loadSVG' }, // Base texture options
		});
		this.assetsAliases.push(alias);
	}

	private loadFinishBackground(): void {
		this.finishBackgroundAlias = 'finish-' + this.currentLevel$.value;
		this.assets.add({
			alias: this.finishBackgroundAlias,
			src: `level_${this.currentLevel$.value}_game.png`,
			data: { scaleMode: SCALE_MODES.LINEAR }, // Base texture options
		});
		this.assetsAliases.push(this.finishBackgroundAlias);
	}

	//load animation sheets for player
	private loadPlayerAnimations(): void {
		const animationAliases: string[] = [
			PlayerDirection.UP,
			PlayerDirection.RIGHT,
			PlayerDirection.DOWN,
			PlayerDirection.LEFT,
			PlayerDirection.IDLE,
		];
		animationAliases.forEach(alias => {
			this.assets.add({
				alias,
				src: `players/${this.playerName}/${alias}.json`,
				data: { scaleMode: SCALE_MODES.LINEAR }, // Base texture options
			});
		});
		this.assetsAliases.push(...animationAliases);
	}

	private loadEnemyAnimations(): void {
		const level = this.currentLevel$.value;
		this.assets.add({
			alias: `enemy_left_${level}`,
			src: `enemy/level-${level}/run_left/run_left.json`,
			data: { scaleMode: SCALE_MODES.LINEAR }, // Base texture options
		});
		this.assets.add({
			alias: `enemy_right_${level}`,
			src: `enemy/level-${level}/run_right/run_right.json`,
			data: { scaleMode: SCALE_MODES.LINEAR }, // Base texture options
		});
		this.assetsEnemiesAliases.push(
			...[`enemy_left_${level}`, `enemy_right_${level}`]
		);
	}

	private loadArrows(): void {
		this.assets.add({
			alias: 'arrow',
			src: 'arrow.svg',
			data: { scaleMode: SCALE_MODES.LINEAR }, // Base texture options
		});
		this.assetsAliases.push('arrow');
	}

	// methods to setup the game
	private setup(): void {
		this.addBackgroundToStage();
		this.addFinishImageToStage();
		this.addPlayerToStage();
		this.addEnemyToStage();
		// this.addGridToStage();
		this.updateStage();
	}

	private updateStage(): void {
		this.app.ticker.add(delta => {
			if (this.filledArea$.value >= this.areaToFill$.value) {
				this.finishGame();
			}
			if (this.restLives$.value <= -1) {
				this.loseGame();
			} else {
				this.player.update(delta);

				if (
					!this.player.isOnGameField$.value &&
					this.player.currentRoute.length
				) {
					this.checkMatrixToFill();
				}
				this.checkEnemyCollision(delta);
			}
		});
	}

	private checkMatrixToFill() {
		if (!this.player.isOnGameField$.value && this.player.currentRoute.length) {
			this.gameMatrix = this.player.currentMatrix.map(row => [...row]);
			this.enemies.forEach(e => (e.gameMatrix = this.gameMatrix));
			this.findAndFillSmallestArea();
			this.player.emptyRoute();
		}
	}

	private checkEnemyCollision(delta: number) {
		this.enemies.forEach(e => {
			e.update(delta);
			if (this.player.isOnGameField$.value && this.player.currentRoute.length) {
				const collusionWithArrows = this.player.arrowSprites.some(a =>
					this.isCollision(e.enemyBounds, this.player.getArrowBounds(a))
				);
				if (collusionWithArrows) {
					this.animateFailure();
					return this.player.crash();
				}
				if (this.isCollision(e.enemyBounds, this.player.playerBounds)) {
					this.animateFailure();

					return this.player.crash();
				}
			}
		});
	}

	private addBackgroundToStage(): void {
		const background = Sprite.from('field');
		background.roundPixels = true;
		background.height = this.app.screen.height;
		background.width = this.app.screen.width;
		background.zIndex = 0;
		this.app.stage.addChild(background);
	}

	private addFinishImageToStage(): void {
		const colorMatrix = new PIXI.ColorMatrixFilter();
		colorMatrix.blackAndWhite(false);
		const imageTexture = Texture.from(this.finishBackgroundAlias);
		const finishImage = Sprite.from(imageTexture);
		finishImage.width = this.app.screen.width - this.cellSize * 4;
		finishImage.height = this.app.screen.height - this.cellSize * 4;
		finishImage.position.set(this.cellSize * 2, this.cellSize * 2);
		finishImage.filters = [colorMatrix];
		this.app.stage.addChild(finishImage);

		this.maskGraphics = new PIXI.Graphics();
		finishImage.mask = this.maskGraphics;
		this.finishImage = finishImage;
		this.app.stage.addChild(this.maskGraphics);
	}

	private addGridToStage(): void {
		const grid = new PIXI.Graphics();
		grid.lineStyle(1, 0xffffff, 1); // стиль линии: толщина, цвет, прозрачность

		// Отрисовка горизонтальных линий сетки
		for (let i = 0; i <= this.gridHeight; i++) {
			grid.moveTo(0, i * this.cellSize);
			grid.lineTo(this.gridWidth * this.cellSize, i * this.cellSize);
		}

		// Отрисовка вертикальных линий сетки
		for (let j = 0; j <= this.gridWidth; j++) {
			grid.moveTo(j * this.cellSize, 0);
			grid.lineTo(j * this.cellSize, this.gridHeight * this.cellSize);
		}

		grid.zIndex = 1;

		this.app.stage.addChild(grid);
	}

	private addPlayerToStage(): void {
		this.player = new Player(
			this.app,
			this.assets,
			this.cellSize,
			GAME_MATRIX,
			this.restLives$
		);
		const startX = this.cellSize * 2 - this.cellSize / 2;
		this.player.initPlayer(this.app.screen.height / 2, startX);
		this.app.stage.addChild(this.player.displayObject);
	}

	private addEnemyToStage(): void {
		const level = this.currentLevel$.value;
		this.enemies = Array.from(
			{ length: level + 1 },
			_ =>
				new Enemy(
					this.gameMatrix,
					this.assets,
					this.cellSize,
					level,
					this.player.lerpFactor
				)
		);
		this.enemies.forEach((enemy: Enemy, index: number) => {
			enemy.initEnemy(index + 1);
			this.app.stage.addChild(enemy.displayObject);
		});
	}

	private updateMaskWithMatrix(matrix: number[][]): void {
		if (!this.maskGraphics) {
			return;
		}
		// Обновление маски в соответствии с матрицей игрового поля
		this.maskGraphics.clear();
		this.maskGraphics.beginFill(0xffffff); // белый цвет для полной видимости
		for (let i = 0; i < matrix.length; i++) {
			for (let j = 0; j < matrix[i].length; j++) {
				if (matrix[i][j] === 1) {
					// Если игрок "открыл" клетку, делаем её видимой
					this.maskGraphics.drawRect(
						j * this.cellSize, // смещение на одну клетку
						i * this.cellSize,
						this.cellSize,
						this.cellSize
					);
				}
			}
		}
		this.maskGraphics.endFill();
		this.player.isRoundFinished$.next(false);
		this.calculateFilledArea();
	}

	public findAndFillSmallestArea(): void {
		const areas = this.findAllAreas();

		const areasWithEnemy: { area: number[][]; hasEnemy: boolean }[] = areas.map(
			area => ({
				area,
				hasEnemy: this.enemies
					.map(e => this.isEnemyOnFilledField(area, e))
					.some(e => e),
			})
		);

		const routeToFill = [
			...this.player.currentRoute.map(({ gridX, gridY }) => [gridX, gridY]),
		];

		if (areas.length <= 1 && routeToFill) {
			return this.fillArea(routeToFill);
		}

		const smallestArea =
			areasWithEnemy.at(0).area.length > areasWithEnemy.at(1).area.length
				? areasWithEnemy.at(1)
				: areasWithEnemy.at(0);
		const biggestArea =
			areasWithEnemy.at(0).area.length <= areasWithEnemy.at(1).area.length
				? areasWithEnemy.at(1)
				: areasWithEnemy.at(0);

		if (areas?.length === 2) {
			if (!smallestArea.hasEnemy) {
				return this.fillArea(smallestArea.area);
			} else if (!biggestArea.hasEnemy) {
				return this.fillArea(biggestArea.area);
			} else if (smallestArea.hasEnemy && biggestArea.hasEnemy) {
				return this.fillArea(routeToFill);
			}
		} else if (areas.length > 2) {
			if (areasWithEnemy.every(a => a.hasEnemy)) {
				return this.fillArea(routeToFill);
			}
			const filteredAreas = areasWithEnemy.filter(a => !a.hasEnemy);
			if (filteredAreas.length > 1) {
				const allSmallAreas = filteredAreas.sort(
					(a, b) => a.area.length - b.area.length
				);
				return allSmallAreas.forEach(a => this.fillArea(a.area));
			} else {
				return this.fillArea(filteredAreas.at(0).area);
			}
		}
	}

	private isEnemyOnFilledField(area: number[][], enemy: Enemy): boolean {
		const { gridY, gridX } = enemy.getPositionInGrid();

		return area.some(([x, y]) => x === gridX && y === gridY);
	}

	private findAllAreas(): number[][][] {
		const visited = this.player.currentMatrix
			.map(row => [...row])
			.map(row => row.map(cell => cell === 2 || cell === 1));
		const areas = [];

		for (let y = 0; y < this.gridHeight; y++) {
			for (let x = 0; x < this.gridWidth; x++) {
				if (!visited[y][x] && this.gameMatrix[y][x] === 0) {
					const area: number[][] = [];
					this.floodFill(x, y, visited, area);
					areas.push(area);
				}
			}
		}
		return areas;
	}

	private floodFill(
		x: number,
		y: number,
		visited: boolean[][],
		area: number[][]
	): void {
		if (x < 0 || y < 0 || x >= this.gridWidth || y >= this.gridHeight) return;
		if (visited[y][x]) return;

		visited[y][x] = true;
		if (this.gameMatrix[y][x] === 0) {
			area.push([x, y]);

			// Рекурсивно заполняем соседние клетки
			this.floodFill(x + 1, y, visited, area);
			this.floodFill(x - 1, y, visited, area);
			this.floodFill(x, y + 1, visited, area);
			this.floodFill(x, y - 1, visited, area);
		}
	}

	private fillArea(area: number[][]): void {
		area.forEach(([x, y], index) => {
			this.gameMatrix[y][x] = 1;
			// Закрашиваем клетку
			// Тут можно добавить логику для визуализации закрашивания в игре
			this.updateMaskWithMatrix(this.gameMatrix);
		});
		this.player.currentMatrix = this.gameMatrix.map(row => [...row]);
		this.player.updateGameMatrix();
	}

	private calculateFilledArea(): void {
		let filledArea = 0;
		for (let y = 0; y < this.gameMatrix.length; y++) {
			for (let x = 0; x < this.gameMatrix[y].length; x++) {
				if (this.gameMatrix[y][x] === 1) {
					++filledArea;
				}
			}
		}
		const filledAreaInPercent = Math.ceil((filledArea * 100) / this.area);
		this.filledArea$.next(filledAreaInPercent);
	}

	private resetMatrix() {
		this.gameMatrix = GAME_MATRIX.map(row => [...row]);
	}

	private isCollision(spriteA: Rectangle, enemy: Rectangle): boolean {
		// Уменьшаем размеры прямоугольников для проверки столкновений
		// spriteA.width *= 0.4; // Уменьшаем ширину на 20%
		// spriteA.height *= 0.4; // Уменьшаем высоту на 20%
		// spriteA.x += spriteA.width * 0.3; // Сдвигаем прямоугольник, чтобы он оставался по центру
		// spriteA.y += spriteA.height * 0.3;
		//
		// enemy.width *= 0.4; // То же самое для второго спрайта
		// enemy.height *= 0.4;
		// enemy.x += enemy.width * 0.3;
		// enemy.y += enemy.height * 0.3;

		// Проверяем пересечение уменьшенных прямоугольников
		return (
			spriteA.x < enemy.x + enemy.width &&
			spriteA.x + spriteA.width > enemy.x &&
			spriteA.y < enemy.y + enemy.height &&
			spriteA.y + spriteA.height > enemy.y
		);
	}

	public animateFailure(): void {
		// Анимация фона
		this.renderer.setStyle(
			this.failureWrapperContainer.nativeElement,
			'visibility',
			'visible'
		);
		this.renderer.setStyle(
			this.failureWrapperContainer.nativeElement,
			'zIndex',
			'99'
		);
		this.animateBackground();

		// Анимация изображения
		this.animateImage();
		setTimeout(() => {
			this.renderer.setStyle(
				this.failureWrapperContainer.nativeElement,
				'visibility',
				'hidden'
			);
			this.renderer.setStyle(
				this.failureWrapperContainer.nativeElement,
				'zIndex',
				'-1'
			);
		}, 1000);
	}

	private animateBackground(): void {
		const element = this.failureWrapperContainer.nativeElement;
		let opacity = 0;
		const interval = setInterval(() => {
			opacity += 0.05; // Увеличиваем прозрачность
			this.renderer.setStyle(
				element,
				'background',
				`rgba(232, 2, 36, ${opacity})`
			);

			if (opacity >= 1) {
				clearInterval(interval); // Останавливаем увеличение прозрачности
				// Начинаем уменьшать прозрачность
				const decreaseInterval = setInterval(() => {
					opacity -= 0.05;
					this.renderer.setStyle(
						element,
						'background',
						`rgba(232, 2, 36, ${opacity})`
					);

					if (opacity <= 0) {
						clearInterval(decreaseInterval);
						this.renderer.setStyle(element, 'visibility', 'hidden');
					}
				}, 30);
			}
		}, 30);
	}

	private animateImage(): void {
		const element = this.failureWhistleContainer.nativeElement;

		let scale = 0;
		let opacity = 0;
		this.renderer.setStyle(element, 'transform', `scale(0)`);
		this.renderer.setStyle(element, 'opacity', `0`);
		const interval = setInterval(() => {
			scale += 10; // Увеличиваем размер

			opacity = scale >= 500 ? opacity - 0.05 : opacity + 0.05; // Уменьшаем прозрачность после 50% анимации
			this.renderer.setStyle(element, 'transform', `scale(${scale / 1000})`);
			this.renderer.setStyle(element, 'opacity', `${opacity}`);

			if (scale >= 1000) {
				clearInterval(interval);
			}
		}, 10);
	}

	private startFireWorks() {
		const fireworks = new Fireworks(this.fireworksContainer.nativeElement);
		this.renderer.setStyle(
			this.fireworksContainer.nativeElement,
			'visibility',
			'visible'
		);
		this.renderer.setStyle(
			this.fireworksContainer.nativeElement,
			'zIndex',
			'1'
		);
		fireworks.updateOptions({
			hue: {
				min: 360,
				max: 360,
			},
		});
		fireworks.start();
		setTimeout(() => {
			fireworks.stop();
			this.renderer.setStyle(
				this.fireworksContainer.nativeElement,
				'visibility',
				'hidden'
			);
			this.renderer.setStyle(
				this.fireworksContainer.nativeElement,
				'zIndex',
				'-1'
			);
			this.router.navigate(['awards']);
			this.currentLevel$.next(this.currentLevel$.value + 1);
			this.resetGame();
		}, 4000);
	}
}
