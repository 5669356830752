export const enum PlayerName {
  vineeth ='vineeth',
  berbatov ='berbatov',
  samad ='samad',
  rahul ='rahul',
  luna ='luna'
}

export const playerNameMapper: Record<number, PlayerName> = {
  1: PlayerName.vineeth,
  2: PlayerName.berbatov,
  3: PlayerName.samad,
  4: PlayerName.rahul,
  5: PlayerName.luna
}
