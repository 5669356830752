import * as PIXI from 'pixi.js';
import { AssetsClass, Rectangle, Sprite } from 'pixi.js';
import { BehaviorSubject } from 'rxjs';
import {
	PlayerAnimationObject,
	PlayerDirection,
	PlayerPosition,
} from './helper-types.model';

export class Player {
	public isOnGameField$ = new BehaviorSubject<boolean>(false);
	public isRoundFinished$ = new BehaviorSubject<boolean>(false);
	public restLives$: BehaviorSubject<number>;
	private app: PIXI.Application;
	private sprite: PIXI.AnimatedSprite;
	private _stepSize: number = 2;
	private animations: PlayerAnimationObject;
	private assets: AssetsClass;
	public animationSpeed = 1 / 8;
	public lerpFactor = 0.075;
	private _gameMatrix: number[][];
	private _currentMatrix: number[][];
	private targetX: number;
	private targetY: number;
	private startX: number;
	private startY: number;
	public currentDirection: PlayerDirection | null = PlayerDirection.IDLE;
	public currentRoute: PlayerPosition[] = [];
	private _arrowSprites: PIXI.Sprite[] = [];
	private isCrashing = false;
	private boundsGraphics: PIXI.Graphics;

	get gameMatrix(): number[][] {
		return this._gameMatrix;
	}

	set gameMatrix(matrix: number[][]) {
		this._gameMatrix = matrix.map(row => [...row]);
	}

	get currentMatrix(): number[][] {
		return this._currentMatrix;
	}

	set currentMatrix(matrix: number[][]) {
		this._currentMatrix = matrix.map(row => [...row]);
	}

	get stepSize(): number {
		return this._stepSize;
	}

	set stepSize(step: number) {
		this._stepSize = step;
	}

	get arrowSprites(): PIXI.Sprite[] {
		return this._arrowSprites;
	}

	set arrowSprites(sprites: PIXI.Sprite[]) {
		this._arrowSprites = sprites;
	}

	get displayObject(): PIXI.Sprite {
		return this.sprite;
	}

	get playerBounds(): Rectangle {
		const bounds = this.sprite.getBounds();
		const x = bounds.x + this.sprite.width * 0.3;
		const y = bounds.y + this.sprite.height * 0.7;
		const width = bounds.width * 0.4;
		const height = bounds.height * 0.3;
		return new Rectangle(x, y, width, height);
	}

	constructor(
		app: PIXI.Application,
		assets: AssetsClass,
		cellSize: number,
		matrix: number[][],
		restLives$: BehaviorSubject<number>
	) {
		this.restLives$ = restLives$;
		this.app = app;
		this.startX = 24;
		this.startY = this.app.view.height / 3;
		this.gameMatrix = matrix.map(row => [...row]);
		this.currentMatrix = matrix.map(row => [...row]);
		this.assets = assets;
		this.stepSize = cellSize;

		if (cellSize < 15) {
			this.lerpFactor = (cellSize * 0.55) / 100;
		} else if (cellSize >= 15 && cellSize < 25) {
			this.lerpFactor = (cellSize * 0.3) / 100;
		} else if (cellSize >= 25) {
			this.lerpFactor = (cellSize * 0.2) / 100;
		}

		this.initializeAnimations();
	}

	private initializeBounds() {
		this.boundsGraphics = new PIXI.Graphics();
		this.boundsGraphics.lineStyle(1, 0xff2255, 1); // Красный цвет границы
		// Не добавляйте его на сцену здесь, поскольку размеры еще не известны
	}

	private updateBoundsForSprite(sprite: Sprite) {
		const bounds = sprite.getBounds();
		this.boundsGraphics.clear(); // Очищаем предыдущие границы
		this.boundsGraphics.lineStyle(1, 0xff3300, 1); // Красный цвет границы
		// const x = bounds.x + sprite.width * 0.25; // Сдвигаем прямоугольник, чтобы он оставался по центру
		// const y = bounds.y + sprite.height * 0.2;
		const x = bounds.x + this.sprite.width * 0.3;
		const y = bounds.y + this.sprite.height * 0.7;
		const width = bounds.width * 0.4;
		const height = bounds.height * 0.3;
		this.boundsGraphics.drawRect(x, y, width, height); // Рисуем новые границы
		if (!this.boundsGraphics.parent) {
			sprite.parent.addChild(this.boundsGraphics); // Добавляем графику границы на сцену, если еще не добавлена
		}
	}

	getArrowBounds(sprite: Sprite): Rectangle {
		const bounds = sprite.getBounds();
		const x = bounds.x;
		const y = bounds.y;
		const width = this.stepSize;
		const height = this.stepSize;
		return new Rectangle(x, y, width, height);
	}

	public initPlayer(startY: number, startX: number = 12): void {
		this.sprite = this.animations[PlayerDirection.IDLE];
		this.sprite.y = startY;
		this.sprite.x = startX;
		this.sprite.zIndex = 99;
		this.targetX = this.sprite.x;
		this.targetY = this.sprite.y;
		this.idle();
	}

	private updateCurrentMatrix(gridX: number, gridY: number): void {
		if (this.isOnGameField$.value && this.gameMatrix[gridY][gridX] !== 2) {
			this.currentMatrix[gridY][gridX] = 1; // Отмечаем клетку как пройденную
		}
	}

	private initializeAnimations(): void {
		this.animations = {} as PlayerAnimationObject;

		Object.values(PlayerDirection).forEach((alias: PlayerDirection) => {
			const animation = this.assets.get(alias).data.animations;

			this.animations[alias] = PIXI.AnimatedSprite.fromFrames(animation[alias]);
		});

		// Настройка анимаций
		Object.keys(this.animations).forEach((keyRaw: string) => {
			const key = keyRaw as PlayerDirection;
			this.animations[key].animationSpeed = this.animationSpeed;
			this.animations[key].visible = false; // Скрываем все анимации
			this.animations[key].play();
		});
	}
	// Методы для движения
	public moveLeft(): void {
		if (!this.canMove(PlayerDirection.LEFT)) {
			return;
		}
		this.move(PlayerDirection.LEFT);
	}

	public moveRight(): void {
		if (!this.canMove(PlayerDirection.RIGHT)) {
			return;
		}
		this.move(PlayerDirection.RIGHT);
	}

	public moveUp(): void {
		if (!this.canMove(PlayerDirection.UP)) {
			return;
		}
		this.move(PlayerDirection.UP);
	}

	public moveDown(): void {
		if (!this.canMove(PlayerDirection.DOWN)) {
			return;
		}
		this.move(PlayerDirection.DOWN);
	}

	public idle(): void {
		this.changeAnimation(PlayerDirection.IDLE);
	}

	public move(direction: PlayerDirection): void {
		const { gridX, gridY } = this.getPositionInGrid();
		// Обновляем состояние игрового поля
		if (this.canMove(direction)) {
			// Определите новую целевую позицию, даже если игрок уже находится в цели
			this.setTargetPosition(gridX, gridY, direction);

			// Включаем анимацию движения
			if (this.currentDirection !== direction) {
				this.changeAnimation(direction);
				this.currentDirection = direction;
			}
		} else {
			this.idle();
		}
	}

	private changeAnimation(animationKey: PlayerDirection): void {
		// Сохраняем текущие координаты и состояние видимости
		const currentX = this.sprite.x;
		const currentY = this.sprite.y;
		// Останавливаем и удаляем текущий спрайт
		this.sprite.stop();
		this.sprite.visible = false;
		const animationFrames =
			this.assets.get(animationKey).data.animations[animationKey];
		// Создаем новый AnimatedSprite с нужными текстурами
		this.sprite.textures =
			PIXI.AnimatedSprite.fromFrames(animationFrames).textures;
		this.sprite.x = currentX;
		this.sprite.y = currentY;
		this.sprite.zIndex = 99;
		this.sprite.visible = true;
		this.sprite.animationSpeed = this.animationSpeed;
		this.currentDirection = animationKey;
		this.sprite.play();
	}

	public update(delta: number): void {
		if (this.sprite) {
			this.interpolatePosition(delta);
			// this.updateBoundsForSprite(this.sprite);
			if (this.canMove(this.currentDirection)) {
				this.move(this.currentDirection);
			} else {
				// Если игрок не может двигаться дальше, переключаемся на idle
				this.idle();
			}
		}
	}

	private interpolatePosition(delta: number): void {
		if (Math.abs(this.targetX - this.sprite.x) > this.stepSize / 2) {
			this.sprite.x += (this.targetX - this.sprite.x) * this.lerpFactor * delta;
		} else {
			this.sprite.x = this.targetX; // Округление до целевой позиции
		}

		// Плавная интерполяция координаты Y
		if (Math.abs(this.targetY - this.sprite.y) > this.stepSize / 2) {
			this.sprite.y += (this.targetY - this.sprite.y) * this.lerpFactor * delta;
		} else {
			this.sprite.y = this.targetY; // Округление до целевой позиции
		}
	}

	private setTargetPosition(
		gridX: number,
		gridY: number,
		direction: PlayerDirection
	): void {
		const cellCenterX = gridX * this.stepSize + this.stepSize / 2;
		const cellCenterY = gridY * this.stepSize + this.stepSize / 2;

		switch (direction) {
			case PlayerDirection.LEFT:
				this.targetX = cellCenterX - this.stepSize;
				break;
			case PlayerDirection.RIGHT:
				this.targetX = cellCenterX + this.stepSize;
				break;
			case PlayerDirection.UP:
				this.targetY = cellCenterY - this.stepSize;
				break;
			case PlayerDirection.DOWN:
				this.targetY = cellCenterY + this.stepSize;
				break;
		}
		if (
			this.gameMatrix[gridY][gridX] === 2 ||
			this.gameMatrix[gridY][gridX] === 1
		) {
			this.isOnGameField$.next(false);
			if (this.currentRoute.length) {
				// this.isOnGameField$.next(false);
				// this.isRoundFinished$.next(true);
				this.updateGameMatrix();
			}
			// Если находимся в безопасной зоне, обновляем матрицу
			// this.updateGameMatrix();
			// this.isRoundFinished$.next(true);
		} else {
			this.isOnGameField$.next(true);

			this.updateCurrentMatrix(gridX, gridY);

			const lastRouteItem = this.currentRoute.at(-1);
			if (lastRouteItem?.gridX !== gridX || lastRouteItem?.gridY !== gridY) {
				if (this.gameMatrix[gridY][gridX] === 0) {
					const position = { gridX, gridY, direction };
					this.currentRoute.push(position);
					this.addArrowSprite(position);
				}
			}
		}
	}

	private addArrowSprite(cell: PlayerPosition): void {
		const arrowSprite = PIXI.Sprite.from('arrow');

		// Позиционируем стрелку в центре клетки
		arrowSprite.x = cell.gridX * this._stepSize + this._stepSize / 2;
		arrowSprite.y = cell.gridY * this._stepSize + this._stepSize / 2;
		arrowSprite.anchor.set(0.5);
		arrowSprite.zIndex = 0;

		// Вращаем стрелку в зависимости от направления
		switch (cell.direction) {
			case PlayerDirection.UP:
				arrowSprite.rotation = Math.PI / 2;
				break;
			case PlayerDirection.DOWN:
				arrowSprite.rotation = -Math.PI / 2;
				break;
			case PlayerDirection.RIGHT:
				arrowSprite.rotation = Math.PI;
				break;
			case PlayerDirection.LEFT:
				break;
		}

		// Добавляем стрелку на сцену
		this.arrowSprites.push(arrowSprite);
		this.app.stage.addChild(arrowSprite);
	}

	public crash() {
		if (!this.isCrashing && this.currentRoute.length) {
			this.isCrashing = true;
			this.currentMatrix = this.gameMatrix.map(row => [...row]);
			this.restLives$.next(this.restLives$.value - 1);
			if (this.restLives$.value >= 0) {
				this.returnToInitialPosition();
			}
		}
	}

	private returnToInitialPosition() {
		const { gridX, gridY, direction } = this.currentRoute.at(0);
		let initialGridX: number = gridX;
		let initialGridY: number = gridY;
		switch (direction) {
			case PlayerDirection.LEFT:
				initialGridX += 1; // Двигаемся на клетку вправо от начальной позиции
				break;
			case PlayerDirection.RIGHT:
				initialGridX -= 1; // Двигаемся на клетку влево от начальной позиции
				break;
			case PlayerDirection.UP:
				initialGridY += 1; // Двигаемся на клетку вниз от начальной позиции
				break;
			case PlayerDirection.DOWN:
				initialGridY -= 1; // Двигаемся на клетку вверх от начальной позиции
				break;
		}

		this.emptyRoute();
		const cellCenterX = initialGridX * this.stepSize + this.stepSize / 2;
		const cellCenterY = initialGridY * this.stepSize + this.stepSize / 2;
		this.isOnGameField$.next(false);
		this.currentDirection = PlayerDirection.IDLE;
		this.initPlayer(cellCenterY, cellCenterX);
		this.isCrashing = false;
		// this.idle();
	}

	public emptyRoute(): void {
		this.currentRoute = [];
		this.removeArrows();
	}

	public removeArrows(): void {
		this.arrowSprites.forEach(arrow => this.app.stage.removeChild(arrow));
		this.arrowSprites = [];
	}

	private getPositionInGrid(): PlayerPosition {
		return {
			gridX: Math.floor(this.sprite.x / this.stepSize),
			gridY: Math.floor(this.sprite.y / this.stepSize),
		};
	}

	private canMove(direction: PlayerDirection): boolean {
		const { gridX, gridY } = this.getPositionInGrid();
		let newX = gridX;
		let newY = gridY;
		// Определение новых координат в зависимости от направления
		switch (direction) {
			case PlayerDirection.LEFT:
				newX = gridX - 1;
				break;
			case PlayerDirection.RIGHT:
				newX = gridX + 1;
				break;
			case PlayerDirection.UP:
				newY = gridY - 1;
				break;
			case PlayerDirection.DOWN:
				newY = gridY + 1;
				break;
		}

		// Проверка, что новые координаты находятся в пределах границ игрового поля
		if (newX < 1 || newY < 1 || newX >= 18 || newY >= 28) {
			this.isOnGameField$.next(false);

			if (this.currentRoute.length) {
				this.updateGameMatrix();
			}
			return false;
		}

		// if (
		// 	this.gameMatrix[gridY][gridX] === 2 ||
		// 	this.gameMatrix[gridY][gridX] === 1
		// ) {
		// 	this.isOnGameField$.next(false);
		// 	this.isRoundFinished$.next(true);
		// 	this.updateGameMatrix();
		// }
		this.isOnGameField$.next(true);

		// Проверка, можно ли переместиться на новую клетку
		return true;
	}

	public updateGameMatrix(): void {
		if (!this.isOnGameField$.value && this.currentRoute.length) {
			for (let y = 0; y < this.gameMatrix.length; y++) {
				for (let x = 0; x < this.gameMatrix[y].length; x++) {
					if (this.currentMatrix[y][x] === 1) {
						this.gameMatrix[y][x] = 1;
					}
				}
			}
		}
	}
}
