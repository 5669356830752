import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AwardTabType, LevelState } from '../../models/helper-types.model';
import {NgClass, NgIf} from '@angular/common';

@Component({
	selector: 'app-awards-page-tabs',
	standalone: true,
  imports: [NgIf, NgClass],
	templateUrl: './awards-page-tabs.component.html',
	styleUrl: './awards-page-tabs.component.scss',
})
export class AwardsPageTabsComponent {
	@Input() currentTab: AwardTabType;
	@Input() levelState: LevelState;
	@Output() changeTab = new EventEmitter<void>();
}
