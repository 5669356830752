import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AsyncPipe, NgIf } from '@angular/common';
import { OnboardingModalComponent } from '../../components/modals/onboarding-modal/onboarding-modal.component';
import { BehaviorSubject } from 'rxjs';
import { ModalType } from '../../models/helper-types.model';

@Component({
	selector: 'app-start-page',
	standalone: true,
	imports: [RouterLink, AsyncPipe, NgIf, OnboardingModalComponent],
	templateUrl: './start-page.component.html',
	styleUrl: './start-page.component.scss',
})
export class StartPageComponent {
	public onboarding$ = new BehaviorSubject<ModalType>('close');

	closeOnboarding() {
		this.onboarding$.next('close');
	}
}
