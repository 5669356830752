import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
declare var init: () => {};
@Component({
	selector: 'app-fon-bet-widget',
	standalone: true,
	imports: [],
	template: `
		<div
			id="registrationContainer"
			[attr.data-promo-id]="code"
			[attr.data-hidden-promo-id]="code"></div>
	`,
	styleUrl: './fon-bet-widget.component.scss',
})
export class FonBetWidgetComponent implements OnInit {
	@Input() code: string;
	ngOnInit() {
		init();
	}
}
