import { AwardText } from '../app/models/helper-types.model';

export const GAME_MATRIX: number[][] = [
	[3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
	[3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 3],
	[3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3],
	[3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
];
export const GAME_MATRIX_FINISH: number[][] = [
	[3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
	[3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 3],
	[3, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3],
	[3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
];

export const awardsInfo: AwardText[] = [
	{
		start: 'C.K. Vineeth',
		default: 'C.K. Vineeth',
		failed: '',
		finish: 'C.K. Vineeth',
	},
	{
		start: 'Dimitar Berbatov',
		default: 'Dimitar Berbatov',
		failed: '',
		finish: 'Dimitar Berbatov',
	},
	{
		start: 'Sahal Abdul Samad',
		default: 'Sahal Abdul Samad',
		failed: '',
		finish: 'Sahal Abdul Samad',
	},
	{
		start: 'Rahul K. P.',
		default: 'Rahul K. P.',
		failed: '',
		finish: 'Rahul K. P.',
	},
	{
		start: 'Adrián Luna',
		default: 'Adrián Luna',
		failed: '',
		finish: 'Adrián Luna',
	},
	{
		start: 'Kerala Blasters',
		default: 'Kerala Blasters',
		failed: '',
		finish: 'Kerala Blasters',
	},
];

export const awardsText: AwardText[] = [
	{
		start:
			'C.K. Vineeth is a legend of Indian football and a standout midfielder. He joined Kerala Blasters in 2015, and his speed and goal-scoring ability quickly made him an essential part of the team. Right from his first appearance, Vineeth delivered impressive performances, and in 2016, he netted 5 goals in just 9 matches, cementing his status as a key player for the club.',
		default:
			'C.K. Vineeth is a legend of Indian football and a standout midfielder. He joined Kerala Blasters in 2015, and his speed and goal-scoring ability quickly made him an essential part of the team. Right from his first appearance, Vineeth delivered impressive performances, and in 2016, he netted 5 goals in just 9 matches, cementing his status as a key player for the club.',
		failed: '',
		finish: `Vineeth's most unforgettable moment came in the 2016 match against Goa, where his brace secured a 2-1 victory, giving fans a thrilling experience. That win helped Kerala Blasters reach the ISL final, shaping the club's legacy as we know it today!`,
	},
	{
		start:
			'The Bulgarian star Dimitar Berbatov, a legendary footballer, joined Kerala Blasters in 2017, bringing invaluable experience to the young team and helping them raise their level of play. As part of the team, Berbatov showcased his brilliance in the midfield with exceptional ball control and precise passing. ',
		default:
			'The Bulgarian star Dimitar Berbatov, a legendary footballer, joined Kerala Blasters in 2017, bringing invaluable experience to the young team and helping them raise their level of play. As part of the team, Berbatov showcased his brilliance in the midfield with exceptional ball control and precise passing. ',
		failed: '',
		finish: `One standout moment was the 2-2 draw against ATK in 2017. In this game, Berbatov displayed his power, creating several key chances and scoring a crucial goal in this important match.`,
	},
	{
		start:
			'Sahal Abdul Samad’s career took off quickly, and by the 2018-2019 season with Kerala Blasters, the young player was already among India’s top footballers for key passes. In 2021, he earned the Hero of the Match award for his brilliant performance against East Bengal FC. His technical skill and ability to create chances in midfield quickly made Sahal a fan favorite.',
		default:
			'Sahal Abdul Samad’s career took off quickly, and by the 2018-2019 season with Kerala Blasters, the young player was already among India’s top footballers for key passes. In 2021, he earned the Hero of the Match award for his brilliant performance against East Bengal FC. His technical skill and ability to create chances in midfield quickly made Sahal a fan favorite.',
		failed: '',
		finish: `Thanks to his outstanding play and a brilliant goal in the 2022 ISL semifinal, Kerala Blasters defeated Jamshedpur and advanced to the final, solidifying Sahal’s status as a key player for the team.`,
	},
	{
		start:
			'Rahul K.P. is the second youngest forward in the history of Kerala Blasters. He scored his first goal for the club in 2019 during a match against Hyderabad, and since then, he has become increasingly important to the team each year.',
		default:
			'Rahul K.P. is the second youngest forward in the history of Kerala Blasters. He scored his first goal for the club in 2019 during a match against Hyderabad, and since then, he has become increasingly important to the team each year.',
		failed: '',
		finish: `One of his most significant achievements with Kerala Blasters came in 2021 when he scored a stunning goal in overtime against Bengaluru. His impressive performance that season made Rahul one of the most valuable players. Additionally, in the 2022/23 season, his decisive goal against Chennaiyin FC secured a vital home victory for the team.`,
	},
	{
		start:
			'Adrián Luna is the heartbeat of the midfield and an irreplaceable captain of Kerala Blasters. Since joining the team in 2021, he has consistently topped the club charts for assists and goals, regularly earning the title of best player.',
		default:
			'Adrián Luna is the heartbeat of the midfield and an irreplaceable captain of Kerala Blasters. Since joining the team in 2021, he has consistently topped the club charts for assists and goals, regularly earning the title of best player.',
		failed: '',
		finish: `Adrián made history by scoring the opening goal in a record-breaking match for Kerala Blasters, which ended in a stunning 8-0 victory over Mumbai City FC. In the semifinal against Jamshedpur, his goal secured a place for the Blasters in the Indian Super League final.`,
	},
	{
		start:
			'Kerala Blasters FC is celebrating its first decade, and we want to extend our heartfelt thanks to you, our loyal fans, for your incredible support! Thank you for being with us through these vibrant years. Your passion and encouragement inspire us to strive for new victories, and we’re on this journey together with you!\n',
		default:
			'Kerala Blasters FC is celebrating its first decade, and we want to extend our heartfelt thanks to you, our loyal fans, for your incredible support! Thank you for being with us through these vibrant years. Your passion and encouragement inspire us to strive for new victories, and we’re on this journey together with you!',
		failed: '',
		finish: `<strong class="final-text">Congratulations!</strong> You’re now part of the draw for our exclusive merchandise collection. Join us at the event on October 13, where we’ll select winners from those who complete the first three levels.
  <br/><br/>
Stay tuned to our website for updates on the lucky winners who will receive stylish jerseys, VIP tickets, the anniversary kit, and a signed ball from the players.`,
	},
];
