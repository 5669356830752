import * as PIXI from 'pixi.js';
import { AssetsClass, Rectangle } from 'pixi.js';
import {
	Coordinates,
	EnemyAnimationObject,
	EnemyCoordinates,
	EnemyDirection,
	EnemyPosition,
} from './helper-types.model';

export const enemyCoordinates: EnemyCoordinates = {
	1: {
		gridX: 12,
		gridY: 3,
		direction: EnemyDirection.LEFT_DOWN,
	},
	2: {
		gridX: 3,
		gridY: 22,
		direction: EnemyDirection.RIGHT_UP,
	},
	3: {
		gridX: 16,
		gridY: 13,
		direction: EnemyDirection.RIGHT_DOWN,
	},
	4: {
		gridX: 16,
		gridY: 26,
		direction: EnemyDirection.LEFT_UP,
	},
	5: {
		gridX: 12,
		gridY: 26,
		direction: EnemyDirection.LEFT_DOWN,
	},
	6: {
		gridX: 4,
		gridY: 27,
		direction: EnemyDirection.RIGHT_UP,
	},
};

export class Enemy {
	public level: number;
	private _gameMatrix: number[][];
	private lerpFactor: number = 0.05; // interpolation speed
	private animationSpeed = 1 / 8;
	private assets: AssetsClass;
	private _stepSize: number = 2;
	private targetX: number;
	private targetY: number;
	private direction: EnemyDirection;
	private sprite: PIXI.AnimatedSprite;
	private animations: EnemyAnimationObject;
	private initialCoordinates = enemyCoordinates;
	private boundsGraphics: PIXI.Graphics;

	set gameMatrix(matrix: number[][]) {
		this._gameMatrix = matrix;
	}

	get gameMatrix(): number[][] {
		return this._gameMatrix;
	}

	get stepSize(): number {
		return this._stepSize;
	}

	set stepSize(step: number) {
		this._stepSize = step;
	}

	get displayObject(): PIXI.Sprite {
		return this.sprite;
	}

	constructor(
		matrix: number[][],
		assets: AssetsClass,
		cellSize: number,
		level: number,
		speed: number
	) {
		this.lerpFactor = speed / 2;
		this.level = level;
		this.assets = assets;
		this.stepSize = cellSize;
		this.gameMatrix = matrix;
		this.initializeAnimations();
	}

	private initializeBounds() {
		this.boundsGraphics = new PIXI.Graphics();
		this.boundsGraphics.lineStyle(1, 0xff3300, 1); // Красный цвет границы
		// Не добавляйте его на сцену здесь, поскольку размеры еще не известны
	}

	private initializeAnimations() {
		this.animations = {} as EnemyAnimationObject;

		Object.values(EnemyDirection).forEach((direction: EnemyDirection) => {
			let alias: string;
			switch (direction) {
				case EnemyDirection.LEFT_DOWN:
				case EnemyDirection.LEFT_UP:
					alias = `enemy_left_${this.level}`;
					break;
				case EnemyDirection.RIGHT_DOWN:
				case EnemyDirection.RIGHT_UP:
					alias = `enemy_right_${this.level}`;
			}
			const animation = this.assets.get(alias).data.animations;
			this.animations[direction] = PIXI.AnimatedSprite.fromFrames(
				animation[alias]
			);
		});
	}

	public initEnemy(order: number) {
		const position = this.initialCoordinates[order];
		this.sprite = this.animations[position.direction];
		const { x, y } = this.calculateInitialCoordinates(position);
		this.sprite.y = y;
		this.sprite.x = x;
		this.sprite.zIndex = 2;
		this.sprite.visible = true;
		this.sprite.animationSpeed = this.animationSpeed;
		this.direction = position.direction;
		this.calculateNextPosition(this.direction);
		this.sprite.play();
	}

	get enemyBounds(): Rectangle {
		const bounds = this.sprite.getBounds();
		const x = bounds.x + this.sprite.width * 0.32;
		const y = bounds.y + this.sprite.height * 0.8;
		const width = bounds.width * 0.35;
		const height = bounds.height * 0.2;
		return new Rectangle(x, y, width, height);
	}

	private updateBounds() {
		const bounds = this.sprite.getBounds();
		this.boundsGraphics.clear(); // Очищаем предыдущие границы
		this.boundsGraphics.lineStyle(1, 0xff3300, 1); // Красный цвет границы
		const x = bounds.x + this.sprite.width * 0.32; // Сдвигаем прямоугольник, чтобы он оставался по центру
		const y = bounds.y + this.sprite.height * 0.8;
		this.boundsGraphics.drawRect(
			x,
			y,
			bounds.width * 0.35,
			bounds.height * 0.2
		); // Рисуем новые границы
		if (!this.boundsGraphics.parent) {
			this.sprite.parent.addChild(this.boundsGraphics); // Добавляем графику границы на сцену, если еще не добавлена
		}
	}

	public update(delta: number): void {
		if (this.sprite) {
			this.interpolatePosition(delta);
			if (this.canMove(this.direction)) {
				this.calculateNextPosition(this.direction);
			} else {
				this.changeDirection();
				if (this.canMove(this.direction)) {
					this.calculateNextPosition(this.direction);
				}
			}
		}
	}

	private changeDirection() {
		const { gridX, gridY } = this.getPositionInGrid();

		switch (this.direction) {
			case EnemyDirection.LEFT_DOWN:
				if (
					this.gameMatrix[gridY][gridX - 1] &&
					this.gameMatrix[gridY][gridX - 1] !== 0
				) {
					this.direction = EnemyDirection.RIGHT_DOWN;
				} else {
					this.direction = EnemyDirection.LEFT_UP;
				}
				break;
			case EnemyDirection.RIGHT_DOWN:
				if (
					this.gameMatrix[gridY][gridX + 1] &&
					this.gameMatrix[gridY][gridX + 1] !== 0
				) {
					this.direction = EnemyDirection.LEFT_DOWN;
				} else {
					this.direction = EnemyDirection.RIGHT_UP;
				}
				break;
			case EnemyDirection.RIGHT_UP:
				if (
					this.gameMatrix[gridY][gridX + 1] &&
					this.gameMatrix[gridY][gridX + 1] !== 0
				) {
					this.direction = EnemyDirection.LEFT_UP;
				} else {
					this.direction = EnemyDirection.RIGHT_DOWN;
				}
				break;
			case EnemyDirection.LEFT_UP:
				if (
					this.gameMatrix[gridY][gridX - 1] &&
					this.gameMatrix[gridY][gridX - 1] !== 0
				) {
					this.direction = EnemyDirection.RIGHT_UP;
				} else {
					this.direction = EnemyDirection.LEFT_DOWN;
				}
				break;
		}
		this.changeAnimation(this.direction);
	}

	private changeAnimation(animationKey: EnemyDirection): void {
		// Сохраняем текущие координаты и состояние видимости
		let alias: string;
		switch (animationKey) {
			case EnemyDirection.LEFT_DOWN:
			case EnemyDirection.LEFT_UP:
				alias = `enemy_left_${this.level}`;
				break;
			case EnemyDirection.RIGHT_DOWN:
			case EnemyDirection.RIGHT_UP:
				alias = `enemy_right_${this.level}`;
		}
		const currentX = this.sprite.x;
		const currentY = this.sprite.y;
		// Останавливаем и удаляем текущий спрайт
		this.sprite.stop();
		this.sprite.visible = false;
		const animationFrames = this.assets.get(alias).data.animations[alias];
		// Создаем новый AnimatedSprite с нужными текстурами
		this.sprite.textures =
			PIXI.AnimatedSprite.fromFrames(animationFrames).textures;
		this.sprite.x = currentX;
		this.sprite.y = currentY;
		this.sprite.zIndex = 99;
		this.sprite.visible = true;
		this.sprite.animationSpeed = this.animationSpeed;
		this.sprite.play();
	}

	private calculateNextPosition(direction: EnemyDirection): void {
		const { gridX, gridY } = this.getPositionInGrid();

		const cellCenterX = gridX * this.stepSize + this.stepSize / 2;
		const cellCenterY = gridY * this.stepSize + this.stepSize / 2;
		switch (direction) {
			case EnemyDirection.LEFT_DOWN:
				this.targetX = cellCenterX - this.stepSize;
				this.targetY = cellCenterY + this.stepSize;
				break;
			case EnemyDirection.LEFT_UP:
				this.targetX = cellCenterX - this.stepSize;
				this.targetY = cellCenterY - this.stepSize;
				break;
			case EnemyDirection.RIGHT_UP:
				this.targetX = cellCenterX + this.stepSize;
				this.targetY = cellCenterY - this.stepSize;
				break;
			case EnemyDirection.RIGHT_DOWN:
				this.targetX = cellCenterX + this.stepSize;
				this.targetY = cellCenterY + this.stepSize;
				break;
		}
	}

	private calculateInitialCoordinates({
		gridX,
		gridY,
	}: EnemyPosition): Coordinates {
		const x = gridX * this.stepSize + this.stepSize / 2;
		const y = gridY * this.stepSize + this.stepSize / 2;
		return { x, y };
	}

	private canMove(direction: EnemyDirection): boolean {
		const { gridX: newGridX, gridY: newGridY } =
			this.newGridPosition(direction);
		let moveCondition: boolean;

		switch (direction) {
			case EnemyDirection.LEFT_UP:
				moveCondition =
					this.gameMatrix[newGridY + 1][newGridX] !== 1 &&
					this.gameMatrix[newGridY][newGridX + 1] !== 1;
				break;
			case EnemyDirection.RIGHT_UP:
				moveCondition =
					this.gameMatrix[newGridY + 1][newGridX] !== 1 &&
					this.gameMatrix[newGridY][newGridX - 1] !== 1;
				break;

			case EnemyDirection.RIGHT_DOWN:
				moveCondition =
					this.gameMatrix[newGridY - 1][newGridX] !== 1 &&
					this.gameMatrix[newGridY][newGridX - 1] !== 1;
				break;

			case EnemyDirection.LEFT_DOWN:
				moveCondition =
					this.gameMatrix[newGridY - 1][newGridX] !== 1 &&
					this.gameMatrix[newGridY][newGridX + 1] !== 1;
				break;
		}

		return (
			this.gameMatrix[newGridY][newGridX] === 0 &&
			moveCondition &&
			newGridX > 1 &&
			newGridY > 1 &&
			newGridX < 19 &&
			newGridY < 29
		);
	}

	private newGridPosition(direction: EnemyDirection): EnemyPosition {
		const { gridX, gridY } = this.getPositionInGrid();

		let newGridX: number;
		let newGridY: number;
		switch (direction) {
			case EnemyDirection.LEFT_DOWN:
				newGridX = gridX - 1;
				newGridY = gridY + 1;
				break;
			case EnemyDirection.LEFT_UP:
				newGridX = gridX - 1;
				newGridY = gridY - 1;
				break;
			case EnemyDirection.RIGHT_UP:
				newGridX = gridX + 1;
				newGridY = gridY - 1;
				break;
			case EnemyDirection.RIGHT_DOWN:
				newGridX = gridX + 1;
				newGridY = gridY + 1;
				break;
		}
		return { gridX: newGridX, gridY: newGridY };
	}

	public getPositionInGrid(): EnemyPosition {
		return {
			gridX: Math.floor(this.sprite.x / this.stepSize),
			gridY: Math.floor(this.sprite.y / this.stepSize),
		};
	}

	private interpolatePosition(delta: number): void {
		if (Math.abs(this.targetX - this.sprite.x) > this.stepSize / 2) {
			this.sprite.x += (this.targetX - this.sprite.x) * this.lerpFactor * delta;
		} else {
			this.sprite.x = this.targetX; // Округление до целевой позиции
		}

		// Плавная интерполяция координаты Y
		if (Math.abs(this.targetY - this.sprite.y) > this.stepSize / 2) {
			this.sprite.y += (this.targetY - this.sprite.y) * this.lerpFactor * delta;
		} else {
			this.sprite.y = this.targetY; // Округление до целевой позиции
		}
	}
}
