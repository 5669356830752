import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
	selector: 'app-onboarding-modal',
	standalone: true,
	templateUrl: './onboarding-modal.component.html',
	styleUrl: './onboarding-modal.component.scss',
	imports: [NgIf],
})
export class OnboardingModalComponent {
	@Input() withAuth: boolean = false;
	@Output() closeModal = new EventEmitter<void>();
	@Output() showModal = new EventEmitter<void>();

	close() {
		this.closeModal.emit();
	}
}
