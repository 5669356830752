import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthType } from '../../../models/helper-types.model';
import { BehaviorSubject, catchError, EMPTY, firstValueFrom, tap } from 'rxjs';
import { AsyncPipe, CommonModule } from '@angular/common';
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FonBetWidgetComponent } from '../../fon-bet-widget/fon-bet-widget.component';
import {DataService} from "../../../services/data.service";

@Component({
	selector: 'app-authentication-modal',
	standalone: true,
	imports: [
		AsyncPipe,
		CommonModule,
		ReactiveFormsModule,
		NgxMaskDirective,
		HttpClientModule,
		FonBetWidgetComponent,
	],
	templateUrl: './authentication-modal.component.html',
	styleUrl: './authentication-modal.component.scss',
})
export class AuthenticationModalComponent implements OnInit {
	@Input() currentLevel: number;
	@Input() promoCode: string = 'KING200';
	@Output() closeModal = new EventEmitter<void>();
	@Output() showOnboarding = new EventEmitter<void>();
	@Output() showCongratulationsModal = new EventEmitter<void>();
	public formType$ = new BehaviorSubject<AuthType>('registration');
	public form: FormGroup;
	public formVerification: FormGroup;
  public maskPatterns = { 'N': { pattern: new RegExp('[A-Za-z0-9]') } };
	constructor(
		private fb: FormBuilder,
		private http: HttpClient,
    private api: DataService
	) {}

	get level(): number {
		return this.currentLevel - 1;
	}

	get code(): string {
		return `KING200${this.currentLevel - 1}`;
	}

	get phoneControl(): AbstractControl {
		return this.form.get('phoneNumber');
	}

	get nameControl(): AbstractControl {
		return this.form.get('name');
	}

	get codeControl(): AbstractControl {
		return this.form.get('code');
	}

	get emailControl(): AbstractControl {
		return this.form.get('email');
	}

	initWidget() {
		this.formType$.next('widget');
	}

	ngOnInit(): void {
		this.form = this.fb.group({
			name: [null, [Validators.required, Validators.minLength(3)]],
			email: [
				null,
				[
					Validators.required,
          Validators.email
				],
			],
      phoneNumber: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
			// policy: [true, [Validators.requiredTrue]],
		});
	}

	close(): void {
		if (
			this.formType$.value === 'registration' ||
			this.formType$.value === 'widget'
		) {
			return this.closeModal.emit();
		}
		if (
			this.formType$.value === 'verification'
		) {
			return this.formType$.next('registration');
		}

	}

	onSubmit() {
    if(this.formType$.value === 'registration'){
      const { name, email, phoneNumber } = this.form.value;
      this.api.sendUser({userName: name, email, phoneNumber: Number('91' + phoneNumber),  level: this.level}).subscribe(code=> {
        this.formType$.next('verification')
        this.formVerification = this.fb.group({
          code: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
        });
      })
    }
    if(this.formType$.value === 'verification'){

      const { code } = this.formVerification.value;
      firstValueFrom(this.api.verifyUser(code))
      this.closeModal.emit();
      this.showCongratulationsModal.emit();
    }
	}

	toggleModals() {
		this.closeModal.emit();
		this.showCongratulationsModal.emit();
	}
}
