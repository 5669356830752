<div class="modal-wrapper" (click)="closeModal.emit()" (swiperight)="close()" (swipeup)="close()" (swipedown)="close()" (swipeleft)="close()" >
<!--    <div class="video-wrapper">-->
<!--      <video  playsInline autoplay loop muted >-->
<!--          <source src="./assets/images/onboarding-video.mp4" type="video/mp4">-->
<!--          Ваш браузер не поддерживает элемент <code>video</code>.-->
<!--        </video>-->

<!--    </div>-->
  <div class="modal-inner controls">
    <div [ngSwitch]="device">
      <div *ngSwitchCase="'mobile'" class="controls__inner">
        <img class="controls__image" src="./assets/images/swipe.svg" alt="swipe">
        <p class="controls__text">Indicate the direction of movement using swipes, gain control over the field by crossing it, and press your opponent against the goal!</p>
      </div>
      <div *ngSwitchDefault class="controls__inner">
        <img class="controls__image" src="./assets/images/keyboard.svg" alt="keyboard">
        <p class="controls__text">Indicate the direction of movement using the arrow keys or WASD, gain control over the field by crossing it, and press your opponent against the goal!
        </p>
      </div>
    </div>
  </div>
</div>
