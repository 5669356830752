<div class="modal-wrapper">
  <div class="modal-inner onboarding">
    <button class="close" (click)="close()"><img src="./assets/images/close.png" alt="close"></button>
    <div class="onboarding__list">
      <div class="onboarding__item">
        <img class="onboarding__item-image" src="./assets/images/1.png" alt="1">
        <p class="onboarding__item-text">Pass the level and gather Blasters All-Stars team</p>
      </div>
      <div class="onboarding__item">
        <img class="onboarding__item-image" src="./assets/images/2.png" alt="2">
        <p class="onboarding__item-text">For each level you could earn additional prizes!</p>
      </div>
      <div class="onboarding__item">
        <img class="onboarding__item-image" src="./assets/images/3.png" alt="3">
        <p class="onboarding__item-text">The results will be available later offline and online!</p>
      </div>
    </div>
    <div class="onboarding__table">
      <div class="onboarding__table-header">
        <div class="onboarding__table-row">
          <div class="onboarding__table-col _left">Levels</div>
          <div class="onboarding__table-col _right">Rewards</div>
        </div>
      </div>
      <div class="onboarding__table-body">
        <div class="onboarding__table-row">
          <div class="onboarding__table-col">5</div>
          <div class="onboarding__table-col _text">Anniversary kit, autographed ball, and season pass</div>
        </div>
        <div class="onboarding__table-row">
          <div class="onboarding__table-col">4</div>
          <div class="onboarding__table-col _text">Jerseys and VIP tickets</div>
        </div>
        <div class="onboarding__table-row">
          <div class="onboarding__table-col">3</div>
          <div class="onboarding__table-col _text">Scarves</div>
        </div>
        <div class="onboarding__table-row">
          <div class="onboarding__table-col">2</div>
          <div class="onboarding__table-col _text">Merchandise</div>
        </div>
        <div class="onboarding__table-row">
          <div class="onboarding__table-col">1</div>
          <div class="onboarding__table-col _text">Stickers</div>
        </div>
      </div>
    </div>
<!--    <button *ngIf="withAuth" class="onboarding__button button _blue" (click)="showModal.next()">NICE!</button>-->
    <button class="onboarding__button button _blue" (click)="close()">NICE!</button>

<!--    <div class="onboarding__text">*приз будет разделен на равные части между всеми участниками, прошедшими соответствующее необходимое количество уровней игры. C подробными условиями можно ознакомиться в-->
<!--      <a href="https://www.fon.bet/pages/loskov" target="_blank">правилах акции</a>.</div>-->
  </div>
</div>
