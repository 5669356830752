import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-congratulation-modal',
	standalone: true,
	imports: [],
	templateUrl: './congratulation-modal.component.html',
	styleUrl: './congratulation-modal.component.scss',
})
export class CongratulationModalComponent implements OnInit {
	@Output() closeModal = new EventEmitter<void>();

	ngOnInit() {
		setTimeout(() => {
      // this.router.navigate(['/awards']);
      // this.game.levelState$ = 'start';
      // this.game.currentLevel$.next(1);
      window.location.href = 'https://batery.ai/';

      this.closeModal.emit();
		}, 4000);
	}
}
