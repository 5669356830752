<div class="awards-page__buttons" *ngIf="levelState">
  <ng-container *ngIf="currentLevel < 6; else final" [ngSwitch]="levelState">
    <ng-container *ngSwitchCase="'finish'">
      <button class="awards-page__button button _white" [disabled]="currentLevel === 1" (click)="showAuthModal.emit('show')">GET THE PRIZES</button>
      <button class="awards-page__button button _blue"  (click)="changeLevelSettings('start')">TO THE NEXT
        LEVEL!</button>
    </ng-container>
    <ng-container *ngSwitchCase="'failed'">
      <button class="awards-page__button button _white" [ngClass]="{'_disabled': currentLevel <= 1}"  [disabled]="currentLevel <= 1"  (click)="showAuthModal.emit('show')">FINISh and get
        <br> the prizes</button>
      <button class="awards-page__button button _blue"  (click)="changeLevelSettings('start')">TRY AGAIN</button>
    </ng-container>
    <ng-container *ngSwitchCase="'default'">
      <button class="awards-page__button button _blue" (click)="changeLevelSettings('start')">NEXT</button>
    </ng-container>
    <ng-container *ngSwitchCase="'start'">
      <button class="awards-page__button button _blue" routerLink="/game">START MATCH!</button>
    </ng-container>
  </ng-container>
  <ng-template #final>
    <ng-container [ngSwitch]="levelState">
      <ng-container *ngSwitchCase="'finish'">
        <button class="awards-page__button button _blue" (click)="changeLevelSettings('default')">NEXT</button>
      </ng-container>
      <ng-container *ngSwitchCase="'default'">
        <button class="awards-page__button button _blue" (click)="changeLevelSettings('start')">NEXT</button>
      </ng-container>
      <ng-container *ngSwitchCase="'start'">
        <button class="awards-page__button button _blue" (click)="showAuthModal.emit('show')">FINISH AND GET THE PRIZES</button>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
