import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { OnboardingModalComponent } from '../../components/modals/onboarding-modal/onboarding-modal.component';
import {
	AsyncPipe,
	CommonModule,
	NgClass,
	NgForOf,
	NgIf,
	NgStyle,
} from '@angular/common';
import { GameService } from '../../services/game.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { awardsText } from '../../../assets/data';
import {
	AwardTabType,
	AwardText,
	LevelState,
	ModalType,
} from '../../models/helper-types.model';
import { state } from '@angular/animations';
import { AuthenticationModalComponent } from '../../components/modals/authentication-modal/authentication-modal.component';
import { AwardsPagePopupComponent } from '../../components/awards-page-popup/awards-page-popup.component';
import { AwardsPageTabsComponent } from '../../components/awards-page-tabs/awards-page-tabs.component';
import { AwardsPageSectionComponent } from '../../components/awards-page-section/awards-page-section.component';
import { AwardsPageButtonsComponent } from '../../components/awards-page-buttons/awards-page-buttons.component';
import { CongratulationModalComponent } from '../../components/modals/congratulation-modal/congratulation-modal.component';
import {
  AwardsDescriptionModalComponent
} from "../../components/modals/awards-description-modal/awards-description-modal.component";

@Component({
	selector: 'app-awards-page',
	standalone: true,
  imports: [
    RouterLink,
    OnboardingModalComponent,
    CommonModule,
    AuthenticationModalComponent,
    AwardsPagePopupComponent,
    AwardsPageTabsComponent,
    AwardsPageSectionComponent,
    AwardsPageButtonsComponent,
    CongratulationModalComponent,
    AwardsDescriptionModalComponent,
  ],
	templateUrl: './awards-page.component.html',
	styleUrl: './awards-page.component.scss',
})
export class AwardsPageComponent implements OnInit {
	onboardingWithAuth$ = new BehaviorSubject<boolean>(true);
	levelState$: Observable<LevelState>;
	currentTab$ = new BehaviorSubject<AwardTabType>('info');
	showAuthModal$ = new BehaviorSubject<ModalType>('close');
	congratulation$ = new BehaviorSubject<ModalType>('close');
	public onboarding$ = new BehaviorSubject<ModalType>('close');
	public prizes$ = new BehaviorSubject<ModalType>('close');

	constructor(private service: GameService) {}
	currentLevel$: Observable<number>;

	ngOnInit() {
		// this.levelState$ = new BehaviorSubject('finish');
		this.levelState$ = this.service.levelState$;
		// this.currentLevel$ = new BehaviorSubject<number>(3);
		this.currentLevel$ = this.service.currentLevel$;
	}

	showOnboarding(withAuth: boolean) {
		this.onboardingWithAuth$.next(withAuth);
		this.onboarding$.next('show');
	}

	changeTab() {
		this.currentTab$.next(
			this.currentTab$.value === 'info' ? 'trophy' : 'info'
		);
	}

	setNewLevelState(state: LevelState) {
		switch (state) {
			case 'default':
				this.currentTab$.next('trophy');
				break;
			case 'finish':
			case 'start':
				this.currentTab$.next('info');
				break;
		}
		this.service.levelState$ = state;
	}

	closeOnboarding() {
		this.onboardingWithAuth$.next(false);
		this.onboarding$.next('close');
	}

	closePrizes() {
		this.prizes$.next('close')
	}

	toggleModal() {
		this.onboarding$.next('close');
		this.showAuthModal$.next('show');
	}

  closeCongratulation() {
    this.congratulation$.next('close');
  }
}
