import * as PIXI from 'pixi.js';

export type AuthType = 'widget' | 'registration' | 'verification';

export enum PlayerDirection {
	IDLE = 'idle',
	UP = 'up',
	RIGHT = 'right',
	DOWN = 'down',
	LEFT = 'left',
}

export enum EnemyDirection {
	RIGHT_UP = 'right_up',
	RIGHT_DOWN = 'right_down',
	LEFT_UP = 'left_up',
	LEFT_DOWN = 'left_down',
}

export interface Position<T> {
	gridX: number;
	gridY: number;
	direction?: T;
}

export type EnemyPosition = Position<EnemyDirection>;
export type PlayerPosition = Position<PlayerDirection>;
export type AwardText = {
	start: string;
	finish: string;
	default: string;
	failed: string;
};

export type LevelState = 'start' | 'finish' | 'default' | 'failed';
export type AwardTabType = 'info' | 'trophy';
export interface Coordinates {
	x: number;
	y: number;
}

export type PlayerAnimationObject = {
	[K in PlayerDirection]: PIXI.AnimatedSprite;
};

export type EnemyAnimationObject = {
	[K in EnemyDirection]: PIXI.AnimatedSprite;
};

export type ModalType = 'show' | 'close';

export interface EnemyCoordinates {
	[k: number]: EnemyPosition;
}
