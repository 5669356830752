<div class="start-page">
  <div class="start-page__top">
    <img class="start-page__image" src="./assets/images/blasters-image.svg" alt="blasters">
  </div>
  <div class="start-page__bottom">
    <img class="start-page__gift" src="./assets/images/gift_new.svg" alt="gift" (click)="onboarding$.next('show')">
    <div class="start-page__text">Kerala Blasters FC is celebrating its anniversary! For the past 10 years, we’ve been writing the history of great club together with you. During this time, we’ve experienced countless incredible emotions. <br><br> Batery invites you to dive into the best moments of the club, reminiscing about key players and their legendary matches. Join us on this journey and stand a chance to win valuable prizes!
    </div>
    <button class="start-page__button button _blue" routerLink="awards">TO THE GAME</button>
    <a class="start-page__fontbet" href="#"><img src="./assets/images/batery-logo.png" alt="fonbet-logo"></a>
  </div>
</div>
<app-onboarding-modal *ngIf="(onboarding$ | async) === 'show'" [withAuth]="false" (closeModal)="closeOnboarding()"></app-onboarding-modal>

