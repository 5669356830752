import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
	CommonModule,
	NgSwitch,
	NgSwitchCase,
	NgSwitchDefault,
} from '@angular/common';

export type DeviceType = 'mobile' | 'desktop';

@Component({
	selector: 'app-controls-modal',
	standalone: true,
	imports: [NgSwitch, NgSwitchDefault, NgSwitchCase, CommonModule],
	templateUrl: './controls-modal.component.html',
	styleUrl: './controls-modal.component.scss',
})
export class ControlsModalComponent {
	@Input() device: DeviceType;
	@Output() closeModal = new EventEmitter<void>();
	close() {
		this.closeModal.emit();
	}
}
