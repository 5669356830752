import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { awardsInfo, awardsText } from '../../../assets/data';
import { AwardTabType, LevelState } from '../../models/helper-types.model';
import { NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
	selector: 'app-awards-page-popup',
	standalone: true,
	imports: [NgClass, NgIf, NgSwitch, NgSwitchCase],
	templateUrl: './awards-page-popup.component.html',
	styleUrl: './awards-page-popup.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AwardsPagePopupComponent {
	@Input() currentTab: AwardTabType;
	@Input() levelState: LevelState;
	@Input() level: number;

	protected readonly awardsText = awardsText;
	protected readonly awardsInfo = awardsInfo;
	years: number[] = [2000, 2001, 2002, 2004, 2007, 2024];

	get infoText(): string {
		switch (this.levelState) {
			case 'default':
			case 'start':
				return this.awardsInfo.at(this.level - 1).start;

			case 'finish':
				return this.awardsInfo.at(this.level - 2).finish;

			default:
        return this.awardsInfo.at(this.level - 1).default;
		}
	}

	get text(): string {
		switch (this.levelState) {
			case 'default':
			case 'start':
				return this.awardsText.at(this.level - 1).start;

			case 'finish':
				return this.awardsText.at(this.level - 2).finish;

			default:
				return '';
		}
	}
}
