import {Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Output} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {AwardSlide} from "../../../models/award-slide.interface";
import {SwiperDirective} from "../../../services/swiper.directive";

@Component({
  selector: 'app-awards-description-modal',
  standalone: true,
  imports: [
    NgOptimizedImage,
    SwiperDirective
  ],
  templateUrl: './awards-description-modal.component.html',
  styleUrl: './awards-description-modal.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AwardsDescriptionModalComponent {
  slides : AwardSlide[] = [
    {
      id: 1,
      title: 'Stickers',
      subTitle: 'Level 1 Prize',
      image: '../assets/game/level_1_finish.png',
      description: 'Show off your true colors with exclusive Kerala Blasters stickers! Bold, vibrant, and packed with team spirit — turn your laptop, phone, or any surface into a fan zone!'
    },
    {
      id: 2,
      title: 'Merchandise',
      subTitle: 'Level 2 Prize',
      image: '../assets/game/level_2_finish.png',
      description: 'Expand your support of the club with exclusive merchandise! Each item is designed to highlight your team spirit and demonstrate your passion.'
    },
    {
      id: 3,
      title: 'Scarves',
      subTitle: 'Level 3 Prize',
      image: '../assets/game/level_3_finish.png',
      description: 'Cozy, bold, and full of team spirit — this scarf is a must-have for every dedicated fan.'
    },
    {
      id: 4,
      title: 'Jerseys and VIP tickets',
      subTitle: 'Level 4 Prize',
      image: '../assets/game/level_4_finish.png',
      description: 'Take your fan experience to the next level! Win an official Kerala Blasters jersey and VIP tickets to event. Feel like part of the team and enjoy the game with exclusive access and top-tier seats!'
    },
    {
      id: 5,
      title: 'Anniversary Kit, Autographed Ball, and Season Pass',
      subTitle: 'Level 5 Prize',
      image: '../assets/game/level_5_finish.png',
      description: 'Celebrate Kerala Blasters in the grandest way possible! Take home a limited-edition anniversary kit, a ball signed by the team, and a season pass that to experience every thrilling match live. A dream reward for any true fan!'
    },
  ];
  @Output() closeModal = new EventEmitter<void>();
}
