<div class="header">
	<div class="header__year">
		<img
			class="header__year-image"
			src="./assets/images/calendar.svg"
			alt="calendar" />
		<div class="header__year-texts">
			<div class="header__year-count">{{years[level - 1]}}</div>
			<div class="header__year-text">season</div>
		</div>
	</div>
	<div class="header__field">
		<img
			class="header__field-image"
			src="./assets/images/field.svg"
			alt="field" />
		<div class="header__progress">
			<div class="header__progress-percent">{{ filledSquare }}%</div>
			<div class="header__progress-line">
				<div
					class="header__progress-line-inner"
					[ngStyle]="{ width: filledArea + '%' }"></div>
			</div>
			<div class="header__progress-text">field control</div>
		</div>
	</div>
	<div class="header__whistles">
		<ng-container *ngFor="let live of lives; let i = index">
			<img
				[src]="
					restLives > i
						? './assets/images/whistle.svg'
						: './assets/images/whistle-disabled.svg'
				"
				alt="whistle" />
		</ng-container>
	</div>
</div>
